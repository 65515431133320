import { useEffect } from "react";

const generateUniqueId = (prefix) => {
  return `${prefix}_${new Date().getTime()}_${Math.floor(
    Math.random() * 1000
  )}`;
};

const useScript = (resourceUrl) => {
  useEffect(() => {
    const scriptId = generateUniqueId("mauticScript");
    const script = document.createElement("script");
    script.async = true;
    script.src = resourceUrl;
    script.id = scriptId;
    const scriptContainer = document.getElementById("___mauticScriptContainer");

    if (scriptContainer) {
      scriptContainer.appendChild(script);
    }

    return () => {
      if (scriptContainer) {
        scriptContainer.removeChild(script);
      }
    };
  }, [resourceUrl]);
};
export default useScript;
